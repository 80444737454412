import {MC_Device} from "../../common/iot/MC_Device";
import {DTMInterface} from "./DTMInterface";

export class O2Device extends MC_Device implements DTMInterface {

    public static PARAMETER_DTM: string = "dtm"; // O2 Sensor DTM

    // Get DTM val as a string
    public getDTMStr(defaultValue: string): string {
        // Load DTM from recent data
        const dtmVal: number | null = (this.recent != null && this.recent[O2Device.PARAMETER_DTM] != null)
            ? this.recent[O2Device.PARAMETER_DTM] as number
            : null
        ;
        return (dtmVal != null) ? dtmVal + "" : defaultValue;
    }

    getSettingsUI(): JSX.Element | null {
        return null; // O2 UART modules have no special settings
    }

}