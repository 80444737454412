import "./GasMonitorSettingsTab.css";
import React from "react";
import MCMessageBar, {MCMessageBarContentState, MCMessageBarType} from "../../../common/ui/misc/MCMessageBar";
import {MC_Backend} from "../../../common/MC_Backend";
import {Button, Divider, FormControl, InputLabel, MenuItem, Paper, Select, TextField} from "@mui/material";
import {GasMonitorType, GasMonitorTypes} from "./GasMonitorTypes";
import {MC_Device} from "../../../common/iot/MC_Device";
import {DTMInterface} from "../DTMInterface";
import {GasMonitor, GasMonitorAdminSettings} from "./GasMonitor";
import DetailRow from "../../../common/ui/DetailRow";

interface GasMonitorSettingsTabProps {
    device: MC_Device & DTMInterface;
}
let timerID: number | null = null; // Alert bar timer
function GasMonitorSettingsTab({device}: GasMonitorSettingsTabProps) {
    const allGasTypes: GasMonitorType[] = GasMonitorTypes.ALL_GAS_TYPES;

    // Parse basics
    const dtm: string = device.getDTMStr("None");
    const gasSettings: GasMonitorAdminSettings = device.adminSettings as GasMonitorAdminSettings;
    const unit: string | null = (gasSettings.parameter_unit !== "") ? gasSettings.parameter_unit : null;
    const dlb: number = gasSettings.lower_bound;
    const dlbStr: string = (dlb != null && !isNaN(dlb)) ? "" + dlb : "N/A";
    const dub: number = gasSettings.upper_bound;
    const dubStr: string = (dub != null && !isNaN(dub)) ? "" + dub : "N/A";
    const lowerBoundStr: string = dlbStr + ((unit != null) ? gasSettings.parameter_unit : "");
    const upperBoundStr: string = dubStr + ((unit != null) ? gasSettings.parameter_unit : "");
    const gasRangeStr: string = lowerBoundStr + " - " + upperBoundStr;
    const gasTypeParam: string = (gasSettings.raw_parameter != null)
        ? gasSettings.raw_parameter
        : ""
    ;

    // State
    const [inputDTM, setInputDTM] = React.useState<string>(dtm);
    const [inputParam, setInputParam] = React.useState<string>(gasTypeParam);
    const [opInProgress, setOpInProgress] = React.useState<boolean>(false);
    // Message bar state
    const [msgBarState, setMsgBarState] = React.useState<MCMessageBarContentState>({
        open: false,
        type: "info",
        message: ""
    });

    // Helper fns
    const setBarOpen = (b: boolean) => setMsgBarState({...msgBarState, open: b});
    const showMsg = (type: MCMessageBarType, msg: string) => {
        setMsgBarState({open: true, type: type, message: msg});
        if (timerID != null) {
            window.clearTimeout(timerID);
        }
        timerID = window.setTimeout(() => {
            // Close each msg after 3 seconds
            setBarOpen(false);
        }, 3000);
    };
    const updateDTMFn = () => {
        if (opInProgress) {
            return;
        }
        setOpInProgress(true);
        showMsg("info", "Updating DTM...");
        // Update settings
        const deviceSettings = device.settings;
        deviceSettings[GasMonitorTypes.PARAMETER_DTM] = inputDTM;
        MC_Backend.getInstance().updateDeviceSettings(device.id, deviceSettings).then(() => {
            // Indicate success
            setOpInProgress(false);
            showMsg("success", "DTM Updated.");
        }).catch((err: any) => {
            // Indicate failure
            setOpInProgress(false);
            showMsg("error", "Failed to update DTM: " + err);
        });
    };
    const updateTypeFn = () => {
        if (opInProgress) {
            return;
        } else if (inputParam == null || inputParam == "") {
            showMsg("error", "Missing gas type");
            return;
        }
        // Find the gas type
        const filteredTypes: GasMonitorType[] = allGasTypes.filter((x) => x.parameter === inputParam);
        if (filteredTypes.length === 0) {
            showMsg("error", "Missing gas type");
            return;
        } else if (filteredTypes.length > 1) {
            showMsg("error", "Multiple gas types matching '" + inputParam + "': contact JAS");
            return;
        }
        setOpInProgress(true);
        showMsg("info", "Updating Gas Type...");
        const foundGasType: GasMonitorType = filteredTypes[0];
        // Update admin settings
        const adminSettings = {} as GasMonitorAdminSettings;
        adminSettings.full_type_name = foundGasType.name + " Monitor";
        adminSettings.raw_parameter = foundGasType.parameter;
        adminSettings.parameter_name = foundGasType.name;
        adminSettings.parameter_unit = foundGasType.unit;
        adminSettings.lower_bound = foundGasType.lower_bound;
        adminSettings.upper_bound = foundGasType.upper_bound;
        adminSettings.decimals = foundGasType.decimals;
        MC_Backend.getInstance().callDeviceRemoteAction(
            device.id,
            GasMonitor.ACTION_EDIT_GAS_TYPE,
            adminSettings
        ).then(() => {
            // Indicate success
            setOpInProgress(false);
            showMsg("success", "Monitor gas type updated.");
            device.adminSettings = adminSettings;
        }).catch((err) => {
            // Indicate failure
            setOpInProgress(false);
            showMsg("error", "Failed to update gas type: " + err);
        })
        ;
    };

    // Const update DTM
    return <Paper className={"content-center"} elevation={3}>

        {/* UI op status */}
        <MCMessageBar
            open={msgBarState.open} setOpen={setBarOpen}
            type={msgBarState.type} message={msgBarState.message} />

        {/* Title */}
        <span className={"card-title"}>Gas Monitor Settings</span>

        <Divider className={"divider"} sx={{m: 1}}/>

        <div className={"gas-details"}>
            <DetailRow label={"DTM"} value={dtm}/>
            <DetailRow label={"Gas Type"} value={gasSettings.parameter_name}/>
            <DetailRow label={"Data Range"} value={gasRangeStr}/>
            <DetailRow label={"Decimals allowed"} value={gasSettings.decimals + ""}/>
        </div>

        <Divider className={"divider"} sx={{m: 1}}/>

        {/* DTM */}
        <div className={"monitor-setting-div"}>
            <TextField
                className={"monitor-dtm-form"}
                variant="filled" label={"DTM"} value={inputDTM}
                       onChange={(ev) => setInputDTM(ev.target.value)}
            />
            <div className={"spacer-div"}></div>
            <Button
                className={"update-dtm-btn"}
                variant="contained" color={"primary"}
                disabled={opInProgress}
                onClick={updateDTMFn}>Update DTM</Button>
        </div>

        <Divider className={"divider"} sx={{m: 1}}/>

        <span className={"note"}>The monitor must be "online" to update the gas type.</span>

        {/* Change monitor type */}
        <div className={"monitor-setting-div"}>
            <FormControl variant={"filled"} sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id={"gas_type_label"}>Monitor Gas Type</InputLabel>
                <Select
                    id={"gas_type_select"}
                    labelId={"gas_type_label"}
                    value={inputParam}
                    onChange={(ev) => setInputParam(ev.target.value)}
                >
                    <MenuItem value=""><em>Unselected</em></MenuItem>
                    {allGasTypes.map((x) =>
                        <MenuItem key={x.parameter} value={x.parameter}>
                            {x.name} ({x.lower_bound} - {x.upper_bound}{(x.unit!=null&&x.unit!=="")?" "+x.unit:""})
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            <div className={"spacer-div"}></div>
            <Button
                className={"update-type-btn"} variant="contained"
                color={"primary"} disabled={opInProgress}
                onClick={updateTypeFn}>Update Gas Type</Button>
        </div>

    </Paper>
}

export default GasMonitorSettingsTab;
