import "./ViewPaper.css";
import {MC_Device} from "../../iot/MC_Device";
import {Button} from "@mui/material";
import React from "react";
import DeviceItemList from "../item-list/list/DeviceItemList";

export interface ViewDevicesProps {
    deviceIDs: string[];
    desc: string;
    addAction?: () => void;
    removeAction?: (d: MC_Device) => void;
}

function ViewDevices(props: ViewDevicesProps) {
    return (
        <div>

            <p className={"note"}>
                <strong>{props.desc}</strong>
            </p>

            {/* Devices item list */}
            <DeviceItemList deviceIDs={props.deviceIDs} removeAction={props.removeAction}/>

            <br/>
            {/* [Optional] Add Action */}
            {
                (props.addAction != null)
                &&
                <Button variant="contained" color={"success"}
                        onClick={() => props.addAction!()}>
                    Add Monitor
                </Button>
            }
        </div>
    );
}

export default ViewDevices;