import React from "react";
import {Alert, Snackbar, SnackbarOrigin} from "@mui/material";

// For easier use by consuming components
export interface MCMessageBarContentState {
    open: boolean;
    type: MCMessageBarType;
    message: string;
}

export type MCMessageBarType = "info" | "warning" | "error" | "success";

export interface MCMessageBarState {
    open: boolean;
    setOpen: (open: boolean) => void;
    type: MCMessageBarType;
    message: string;
}
// Common use message bar
function MCMessageBar({open, setOpen, type, message}: MCMessageBarState) {
    const origin: SnackbarOrigin = { vertical: "bottom", horizontal: "center" };
    const alertSx: any = { width: '100%' };
    // UI
    return <React.Fragment>
        <Snackbar
            open={open} onClose={() => setOpen(false)}
            autoHideDuration={null} anchorOrigin={origin}>
            <Alert severity={type} sx={alertSx}>
                {message}
            </Alert>
        </Snackbar>
    </React.Fragment>;
}

export default MCMessageBar;