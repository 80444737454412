import "./ViewPaper.css";
import {MC_Device} from "../../iot/MC_Device";
import React from "react";
import RecentAlertItemList from "../item-list/list/RecentAlertItemList";

export interface ViewDeviceAlertsProps {
    device: MC_Device;
}

function ViewDeviceAlerts(props: ViewDeviceAlertsProps) {
    return (
        <div>

            {/* description */}
            <p className={"note"}>
                Here is a list of alerts triggered by this monitor recently:
            </p>

            {/* Display recently triggered alerts */}
            <RecentAlertItemList deviceID={props.device.id}/>

        </div>
    );
}

export default ViewDeviceAlerts;