import {MC_Device} from "../../../common/iot/MC_Device";
import GasMonitorSettingsTab from "./GasMonitorSettingsTab";
import React from "react";
import {DTMInterface} from "../DTMInterface";
import {GasMonitorTypes} from "./GasMonitorTypes";

export interface GasMonitorAdminSettings {
    full_type_name: string; // Override the readable device type
    raw_parameter: string; // Technical name of the parameter
    parameter_name: string; // Readable name of the parameter
    parameter_unit: string; // Unit of the parameter
    lower_bound: number; // Low end of data allowed (ex 0% of 0-100%)
    upper_bound: number; // High end of data allowed (ex 100% of 0-100%)
    decimals: number; // Number of decimal places to parse too
}
export class GasMonitor extends MC_Device implements DTMInterface {

    public static ACTION_EDIT_GAS_TYPE: string = "edit_gas_type";

    // Get DTM as a string
    public getDTMStr(defaultValue: string): string {
        // Parse dtm from user settings
        let rawDTMAny: any = (this.settings != null)
            ? this.settings[GasMonitorTypes.PARAMETER_DTM]
            : null
        ;
        if (rawDTMAny != null) {
            return "" + rawDTMAny;
        }
        // Parse dtm from admin settings
        rawDTMAny = (this.adminSettings != null)
            ? this.adminSettings[GasMonitorTypes.PARAMETER_DTM]
            : null
        ;
        return (rawDTMAny != null) ? "" + rawDTMAny : defaultValue;
    }

    getSettingsUI(): JSX.Element | null {
        return <GasMonitorSettingsTab device={this}/>;
    }

}
