import "./DevicesTable.css";
import {MC_Device} from "../../iot/MC_Device";
import React, {CSSProperties} from "react";
import {
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow, Tooltip
} from "@mui/material";
import {Link} from "react-router-dom";
import {MC_Constants} from "../../MC_Constants";
import {MC_UI_Helper} from "../../iot/MC_UI_Helper";
import {DTMInterface} from "../../../custom/devices/DTMInterface";

const alignVal: "left" | "center" | "right" = "left";

const linkStyle: CSSProperties = {
    color: "var(--MC_MAIN)",
    textDecorationColor: "var(--MC_MAIN)",
    minWidth: "150px",
    maxWidth: "250px"
};
interface DeviceRowProps {
    device: MC_Device;
    refreshTS: Date;
}
function DeviceRow({device, refreshTS}: DeviceRowProps) {
    const isOnline: boolean = device.isOnline(refreshTS);
    const statusTooltip: string = (isOnline)
        ? "This monitor is online"
        : "This monitor is offline"
    ;
    const dtmStr: string = ((device as any) as DTMInterface).getDTMStr("-");
    const lastSeenStr: string = MC_Constants.getReadableDateStr(device.last_post);
    return (
        <TableRow key={device.id} hover={true}>
            <TableCell align={alignVal}><span className={"name-span"}>{device.name}</span></TableCell>
            <TableCell align={alignVal}>
                <Link to={"/core/devices/" + device.id} style={linkStyle}>
                    <strong>{device.readableJASID}</strong>
                </Link>
            </TableCell>
            <TableCell align={alignVal}>
                <Tooltip title={statusTooltip} placement={"top"}>
                    <strong className={(isOnline) ? "good" : "note"}>
                        <span className={"status-span"}>
                            {(isOnline) ? "ONLINE" : "OFFLINE"}
                        </span>
                    </strong>
                </Tooltip>
            </TableCell>
            <TableCell align={alignVal}>{device.fullType}</TableCell>
            <TableCell align={alignVal}>{dtmStr}</TableCell>
            <TableCell align={alignVal}>
                {MC_UI_Helper.getLicenseCell(device)}
            </TableCell>
            <TableCell align={alignVal}><span className={"last-post-span"}>{lastSeenStr}</span></TableCell>
        </TableRow>
    );
}

export interface DevicesTableProps {
    devices: MC_Device[];
    refreshTS: Date;
    tableCtl?: JSX.Element;
}
function DevicesTable({devices, refreshTS, tableCtl}: DevicesTableProps) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // Handle pagination changes
    const handleChangePage = (event: unknown, newPage: number) => {setPage(newPage);};
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <div className={"table-div"}>
            <TableContainer component={Paper} elevation={6}>

                {/* Optional table control header */}
                {(tableCtl != null) && tableCtl}

                <Divider sx={{width: "100%"}}/>

                {/* Table */}
                <Table>
                    {/* Header with column names */}
                    <TableHead>
                        <TableRow>
                            <TableCell align={alignVal}><strong>Name</strong></TableCell>
                            <TableCell align={alignVal}><strong>Serial Number</strong></TableCell>
                            <TableCell align={alignVal}><strong>Status</strong></TableCell>
                            <TableCell align={alignVal}><strong>Type</strong></TableCell>
                            <TableCell align={alignVal}><strong>DTM</strong></TableCell>
                            <TableCell align={alignVal}><strong>License</strong></TableCell>
                            <TableCell align={alignVal}><strong>Last Seen</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    {/* Table cell for each user */}
                    <TableBody>
                        {
                            devices.map((d, idx) => {
                                let shouldDisplay = (idx >= (page * rowsPerPage)) && (idx < ((page + 1) * rowsPerPage));
                                if (shouldDisplay) {
                                    return <DeviceRow key={d.id} device={d} refreshTS={refreshTS}/>;
                                }
                                return null;
                            })
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            {/* Pagination control */}
                            <TablePagination
                                count={devices.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[10, 25, 50]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}


export default DevicesTable;