
export interface GasMonitorType {
    name: string; // Friendly name (ex: "Oxygen")
    parameter: string; // param to distinguish it programmatically
    unit: string; // readable unit
    lower_bound: number; // Lowest data at 4 mA
    upper_bound: number; // Highest data at 20 mA
    decimals: number; // 0 = no decimals, 1 = 0.1, etc (DEFAULT IS 1)
}

export class GasMonitorTypes {

    public static PARAMETER_DTM: string = "dtm"; // Monitor DTM

    public static ALL_GAS_TYPES: GasMonitorType[] = [
        {
            name: "Acetone",
            parameter: "acetone",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Acetonitrile",
            parameter: "acetonitrile",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Arsine",
            parameter: "arsine_ppm",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1,
            decimals: 2
        },
        {
            name: "Arsine",
            parameter: "arsine_ppb",
            unit: "ppb",
            lower_bound: 0,
            upper_bound: 999,
            decimals: 0
        },
        {
            name: "Acetic Acid",
            parameter: "acetic_acid_ppm",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 30,
            decimals: 1
        },
        {
            name: "Acetic Acid",
            parameter: "acetic_acid_lel",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Acetylene",
            parameter: "acetylene",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Ammonia",
            parameter: "ammonia_75",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 75,
            decimals: 1
        },
        {
            name: "Ammonia",
            parameter: "ammonia_100",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Ammonia",
            parameter: "ammonia_200",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 200,
            decimals: 0
        },
        {
            name: "Ammonia",
            parameter: "ammonia_300",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 300,
            decimals: 0
        },
        {
            name: "Ammonia",
            parameter: "ammonia_1000",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1000,
            decimals: 0
        },
        {
            name: "Boron Trichloride",
            parameter: "boron_trichloride",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 15,
            decimals: 1
        },
        {
            name: "Boron Trifluoride",
            parameter: "boron_trifluoride",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 9,
            decimals: 1
        },
        {
            name: "Bromine",
            parameter: "bromine_1",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1,
            decimals: 2
        },
        {
            name: "Bromine",
            parameter: "bromine_10",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 10,
            decimals: 1
        },
        {
            name: "Butane",
            parameter: "butane",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Butyl Acetate",
            parameter: "butyl_acetate",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Carbon Dioxide",
            parameter: "carbon_dioxide_10000",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 10000,
            decimals: 0
        },
        {
            name: "Carbon Dioxide",
            parameter: "carbon_dioxide_50000",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 50000,
            decimals: 0
        },
        {
            name: "Carbon Monoxide",
            parameter: "carbon_monoxide_100",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Carbon Monoxide",
            parameter: "carbon_monoxide_1000",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1000,
            decimals: 0
        },
        {
            name: "Carbonyl Sulfide",
            parameter: "carbonyl_sulfide",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 50,
            decimals: 1
        },
        {
            name: "Chlorine",
            parameter: "chlorine_1",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1,
            decimals: 2
        },
        {
            name: "Chlorine",
            parameter: "chlorine_3",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 3,
            decimals: 2
        },
        {
            name: "Chlorine",
            parameter: "chlorine_10",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 10,
            decimals: 1
        },
        {
            name: "Chlorine",
            parameter: "chlorine_50",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 50,
            decimals: 1
        },
        {
            name: "Chlorine",
            parameter: "chlorine_80",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 80,
            decimals: 1
        },
        {
            name: "Chlorine",
            parameter: "chlorine_200",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 200,
            decimals: 0
        },
        {
            name: "Chlorine Dioxide",
            parameter: "chlorine_dioxide_1",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1,
            decimals: 2
        },
        {
            name: "Chlorine Dioxide",
            parameter: "chlorine_dioxide_3",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 3,
            decimals: 2
        },
        {
            name: "Chlorine Dioxide",
            parameter: "chlorine_dioxide_5",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 5,
            decimals: 2
        },
        {
            name: "Cyclohexane",
            parameter: "cyclohexane",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Diborane",
            parameter: "diborane_ppm",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1,
            decimals: 2
        },
        {
            name: "Diborane",
            parameter: "diborane_ppb",
            unit: "ppb",
            lower_bound: 0,
            upper_bound: 999,
            decimals: 0
        },
        {
            name: "Dichloromethane",
            parameter: "dichloromethane",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Dichlorosilane",
            parameter: "dichlorosilane",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 15,
            decimals: 1
        },
        {
            name: "Diethyl Ether",
            parameter: "diethyl_ether",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Dimethylformamide",
            parameter: "dimethylformamide",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Dimethylamine",
            parameter: "dimethylamine",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 30,
            decimals: 1
        },
        {
            name: "Disilane",
            parameter: "disilane",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 15,
            decimals: 1
        },
        {
            name: "Dodecane",
            parameter: "dodecane",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Ethane",
            parameter: "ethane",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Ethanol",
            parameter: "ethanol",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Ethyl Acetate",
            parameter: "ethyl_acetate",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Ethyl Alcohol",
            parameter: "ethyl_alcohol",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1000,
            decimals: 0
        },
        {
            name: "Ethylene",
            parameter: "ethylene",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Ethylene Oxide",
            parameter: "ethylene_oxide",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Fluorine",
            parameter: "fluorine_1",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1,
            decimals: 2
        },
        {
            name: "Fluorine",
            parameter: "fluorine_3",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 3,
            decimals: 2
        },
        {
            name: "Fluorine",
            parameter: "fluorine_30",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 30,
            decimals: 1
        },
        {
            name: "Fluorine",
            parameter: "fluorine_60",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 60,
            decimals: 1
        },
        {
            name: "Formic Acid",
            parameter: "formic_acid_15",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 15,
            decimals: 1
        },
        {
            name: "Formic Acid",
            parameter: "formic_acid_50",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 50,
            decimals: 1
        },
        {
            name: "Germane",
            parameter: "germane",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 0.6,
            decimals: 2
        },
        {
            name: "Gasoline",
            parameter: "gasoline",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Heptane",
            parameter: "heptane",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Hexane",
            parameter: "hexane",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Hydrazine",
            parameter: "hydrazine_1",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1,
            decimals: 2
        },
        {
            name: "Hydrazine",
            parameter: "hydrazine_2",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 2,
            decimals: 2
        },
        {
            name: "Hydrochloric Acid",
            parameter: "hydrochloric_acid_15",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 15,
            decimals: 1
        },
        {
            name: "Hydrochloric Acid",
            parameter: "hydrochloric_acid_20",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 20,
            decimals: 1
        },
        {
            name: "Hydrochloric Acid",
            parameter: "hydrochloric_acid_50",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 50,
            decimals: 1
        },
        {
            name: "Hydrogen Fluoride",
            parameter: "hydrogen_fluoride_9",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 9,
            decimals: 1
        },
        {
            name: "Hydrogen Fluoride",
            parameter: "hydrogen_fluoride_30",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 30,
            decimals: 1
        },
        {
            name: "Hydrogen",
            parameter: "hydrogen_1",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 1,
            decimals: 2
        },
        {
            name: "Hydrogen",
            parameter: "hydrogen_4",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 4,
            decimals: 2
        },
        {
            name: "Hydrogen",
            parameter: "hydrogen_100",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Hydrogen Bromide",
            parameter: "hydrogen_bromide_9",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 9,
            decimals: 1
        },
        {
            name: "Hydrogen Bromide",
            parameter: "hydrogen_bromide_15",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 15,
            decimals: 1
        },
        {
            name: "Hydrogen Bromide",
            parameter: "hydrogen_bromide_20",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 20,
            decimals: 1
        },
        {
            name: "Hydrogen Chloride",
            parameter: "hydrogen_chloride_15",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 15,
            decimals: 1
        },
        {
            name: "Hydrogen Chloride",
            parameter: "hydrogen_chloride_20",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 20,
            decimals: 1
        },
        {
            name: "Hydrogen Chloride",
            parameter: "hydrogen_chloride_50",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 50,
            decimals: 1
        },
        {
            name: "Hydrogen Chloride",
            parameter: "hydrogen_chloride_200",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 200,
            decimals: 0
        },
        {
            name: "Hydrogen Cyanide",
            parameter: "hydrogen_cyanide_3",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 3,
            decimals: 2
        },
        {
            name: "Hydrogen Cyanide",
            parameter: "hydrogen_cyanide_30",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 30,
            decimals: 1
        },
        {
            name: "Hydrogen Peroxide",
            parameter: "hydrogen_peroxide",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 9,
            decimals: 1
        },
        {
            name: "Hydrogen Selenide",
            parameter: "hydrogen_selenide",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1,
            decimals: 2
        },
        {
            name: "Hydrogen Sulfide",
            parameter: "hydrogen_sulfide_30",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 30,
            decimals: 1
        },
        {
            name: "Hydrogen Sulfide",
            parameter: "hydrogen_sulfide_200",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 200,
            decimals: 0
        },
        {
            name: "Hydrogen Sulfide",
            parameter: "hydrogen_sulfide_500",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 500,
            decimals: 0
        },
        {
            name: "Iodine",
            parameter: "iodine_1",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1,
            decimals: 2
        },
        {
            name: "Iodine",
            parameter: "iodine_3",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 3,
            decimals: 2
        },
        {
            name: "Isobutyl",
            parameter: "isobutyl",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Isopropyl Alcohol",
            parameter: "isopropyl_alcohol_ppm",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1000,
            decimals: 0
        },
        {
            name: "Isopropyl Alcohol",
            parameter: "isopropyl_alcohol_lel",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Kerosene",
            parameter: "kerosene",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Methane",
            parameter: "methane",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Methanol",
            parameter: "methanol",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Methyl Bromide",
            parameter: "methyl_bromide_10",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 10,
            decimals: 1
        },
        {
            name: "Methyl Bromide",
            parameter: "methyl_bromide_50",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 50,
            decimals: 1
        },
        {
            name: "Monomethylhydrazine",
            parameter: "monomethylhydrazine",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 5,
            decimals: 1
        },
        {
            name: "Nitrate",
            parameter: "nitrate_9",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 9,
            decimals: 1
        },
        {
            name: "Nitrate",
            parameter: "nitrate_15",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 15,
            decimals: 1
        },
        {
            name: "Nitric Acid",
            parameter: "nitric_acid_5",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 5,
            decimals: 1
        },
        {
            name: "Nitric Acid",
            parameter: "nitric_acid_6",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 6,
            decimals: 1
        },
        {
            name: "Nitric Oxide",
            parameter: "nitric_oxide",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Nitrogen Dioxide",
            parameter: "nitrogen_dioxide_2",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 2,
            decimals: 2
        },
        {
            name: "Nitrogen Dioxide",
            parameter: "nitrogen_dioxide_9",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 9,
            decimals: 1
        },
        {
            name: "Nitrogen Dioxide",
            parameter: "nitrogen_dioxide_15",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 15,
            decimals: 1
        },
        {
            name: "Oxygen",
            parameter: "o2_10_ppb",
            unit: "ppb",
            lower_bound: 0,
            upper_bound: 10,
            decimals: 1
        },
        {
            name: "Oxygen",
            parameter: "o2_10_ppm",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 10,
            decimals: 1
        },
        {
            name: "Oxygen",
            parameter: "o2_100_ppm",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Oxygen",
            parameter: "o2_1000_ppm",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1000,
            decimals: 0
        },
        {
            name: "Oxygen",
            parameter: "o2_25_%",
            unit: "%",
            lower_bound: 0,
            upper_bound: 25,
            decimals: 1
        },
        {
            name: "Oxygen",
            parameter: "o2_30_%",
            unit: "%",
            lower_bound: 0,
            upper_bound: 30,
            decimals: 1
        },
        {
            name: "Oxygen",
            parameter: "o2_95_%",
            unit: "%",
            lower_bound: 0,
            upper_bound: 95,
            decimals: 1
        },
        {
            name: "Ozone",
            parameter: "ozone_03",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 0.3,
            decimals: 3
        },
        {
            name: "Ozone",
            parameter: "ozone_1",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1,
            decimals: 2
        },
        {
            name: "Ozone",
            parameter: "ozone_3",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 3,
            decimals: 2
        },
        {
            name: "Ozone",
            parameter: "ozone_300",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 300,
            decimals: 0
        },
        {
            name: "Phosphine",
            parameter: "phosphine_003_ppm",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 0.03,
            decimals: 3
        },
        {
            name: "Phosphine",
            parameter: "phosphine_1_ppm",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 1,
            decimals: 2
        },
        {
            name: "Phosphine",
            parameter: "phosphine_999_ppb",
            unit: "ppb",
            lower_bound: 0,
            upper_bound: 999,
            decimals: 0
        },
        {
            name: "Phosphoryl Chloride",
            parameter: "phosphoryl_chloride",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 15,
            decimals: 1
        },
        {
            name: "Propane",
            parameter: "propane",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Silane",
            parameter: "silane",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 15,
            decimals: 1
        },
        {
            name: "Sulfur Dioxide",
            parameter: "sulfur_dioxide_15",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 15,
            decimals: 1
        },
        {
            name: "Sulfur Dioxide",
            parameter: "sulfur_dioxide_20",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 20,
            decimals: 1
        },
        {
            name: "Tert Butanol",
            parameter: "tert_butanol",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Tetrahydrofuran",
            parameter: "tetrahydrofuran",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Toluene",
            parameter: "toluene",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        },
        {
            name: "Tri-Chlorosilane",
            parameter: "tri_chlorosilane",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 15,
            decimals: 1
        },
        {
            name: "Triethylamine",
            parameter: "triethylamine",
            unit: "ppm",
            lower_bound: 0,
            upper_bound: 30,
            decimals: 1
        },
        {
            name: "Xylene",
            parameter: "xylene",
            unit: "% LEL",
            lower_bound: 0,
            upper_bound: 100,
            decimals: 1
        }
    ];

}